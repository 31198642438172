<template>
    <div class="frame-card">
        <div
            class="item-card"
            v-for="(item, index) in DanhSachTieuChi"
            :key="index"
            @click="SapXep(item, index)"
            :class="item.isSapXep == true ? 'active' : ''"
        >
            <i
                class="mdi mdi-swap-vertical mr-1"
                style="font-size: 16px"
                v-if="item.order == 0"
            ></i>
            <i
                class="mdi mdi-sort-bool-ascending mr-1"
                style="font-size: 16px"
                v-if="item.order == 1"
            ></i>
            <i
                class="mdi mdi-sort-bool-descending mr-1"
                style="font-size: 16px"
                v-if="item.order == 2"
            ></i>
            {{ item.text }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            DanhSachTieuChi: [
                {
                    text: "Giờ XB",
                    value: "thoiGianXuatBenKeHoach",
                    order: 0,
                    isSapXep: false,
                },
                {
                    text: "BKS",
                    value: "bienKiemSoat",
                    order: 0,
                    isSapXep: false,
                },
                {
                    text: "Tuyến vận chuyển",
                    value: "tenTuyen",
                    order: 0,
                    isSapXep: false,
                },
            ],
            isSapXep: false,
            tieuChiSapXep: null,
            kieuSapXepDesc: false,
        };
    },
    computed: {},
    methods: {
        SapXep(item, index) {
            this.DanhSachTieuChi.forEach((e, i) => {
                if (index == i) {
                    e.order = e.order < 2 ? e.order + 1 : 0;
                    this.isSapXep = e.isSapXep = e.order == 0 ? false : true;
                }
                if (index != i) {
                    e.order = 0;
                    e.isSapXep = false;
                }
            });
            this.tieuChiSapXep = item.value;
            this.kieuSapXepDesc = item.order == 1 ? false : item.order == 2 ? true : null;
            this.$emit("LayDanhSachLenh");
        },
    },
};
</script>

<style scoped>
.frame-card {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    align-items: center;
    margin: 0 8px;
}
.frame-card::-webkit-scrollbar {
    display: none;
}
.item-card {
    border: 1px solid #dadce0;
    margin-right: 8px;
    border-radius: 24px;
    padding: 4px 12px 4px 8px;
    width: auto;
    height: 36px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    box-shadow: inset 0 0 5px #dadce0;
}
.item-card.active {
    border: 1px solid #03a9f4;

    box-shadow: inset 0 0 5px #03a9f4;
    color: #03a9f4;
}
</style>
